import ReactDOMServer from "react-dom/server";
import { TabContainer } from "../TabContainer/TabContainer";
import { Translator } from "../i18n/Translator";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useState } from "react";
import Swal from "sweetalert2";

import {
  GoogleGenerativeAI,
  HarmCategory,
  HarmBlockThreshold,
} from "@google/generative-ai";

export const ChatBot = () => {
  const translationToString = (path: string): string => {
    const tabContentString = ReactDOMServer.renderToString(
      <Translator path={path} />
    );
    return tabContentString;
  };
  const [history, setHistory] = useState([
    {
      parts: {
        text: translationToString("chatbot.message"),
        role: "model",
      },
    },
  ]);
  const [prompt, setPrompt] = useState("");
  const [isSending, setIsSending] = useState<boolean>(false);

  async function generateResponse(prompt: string) {
    setIsSending(true);

    async function runChat() {
      const genAI = new GoogleGenerativeAI(
        process.env.REACT_APP_GEMINI_API_KEY!
      );
      const model = genAI.getGenerativeModel({
        model: "gemini-1.5-pro",
      });

      const generationConfig = {
        temperature: 1,
        topK: 1,
        topP: 1,
        maxOutputTokens: 2048,
      };

      const safetySettings = [
        {
          category: HarmCategory.HARM_CATEGORY_HARASSMENT,
          threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
        },
        {
          category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
          threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
        },
        {
          category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
          threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
        },
        {
          category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
          threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
        },
      ];
      const chatHistory = [
        {
          role: "user",
          parts: [
            {
              text: "Olá, sou um assistente de IA do Wagner, e sempre começo a conversa dizendo isso. Respondo apenas questões relacionadas ao currículo dele. Não sou capaz de responder a outras perguntas. Pergunte-me qualquer coisa sobre o currículo dele. E, por favor, seja educado. Sempre responderei na mesma língua da pergunta. Sempre respondo em terceira pessoa.",
            },
            {
              text:
                "Wagner Araujo Ramos" +
                "Desenvolvedor de Software" +
                "Transformando ideias em soluções" +
                "waggt@hotmail.com | +55 41 98499-0858 | Curitiba, Brasil | www.wagdev.com.br" +
                "Educação:" +
                "Análise e Desenvolvimento de Sistemas | UNINTER | Curitiba, Brasil | Conclusão prevista para 06-2026" +
                "Santander Bootcamp 2023 - Desenvolvimento Mobile com Flutter | DIO | Julho - Outubro 2023, Concluído" +
                "Flutter BLoC TDD & Clean Architecture | Project Based | Udemy | Julho - Dezembro 2023, Concluído" +
                "Front-end React | Ada Tech | Outubro 2023 - Janeiro 2024, Concluído" +
                "Experiência 1:" +
                "Junior Software Developer | Massa Automação Industrial | Curitiba, Brasil | Abril 2024 - Até o momento" +
                "Responsável pelo desenvolvimento front-end em flutter web de um sistema de pesagens de trens em movimento." +
                "Resolução de problemas técnicos visando melhoria da qualidade do código." +
                "Implementação de testes uniários e princípios como solid, clean architecture." +
                "Domínio de novas tecnologias: Flutter, SQL Server, Clean Architecture, Flutter Test, WebSocket." +
                "Experiência 2:" +
                "Full Stack Developer | Printer do Brasil | Curitiba, Brasil | Janeiro 2024 - Março 2024" +
                "Aumento de 50% de cobertura de testes front-end." +
                "Resolução de problemas técnicos visando melhoria da qualidade do código." +
                "Desenvolvimento de rotas back-end com mais de 90% de cobertura de testes integrados." +
                "Domínio de novas tecnologias: React, Tailwind, Ant-design, Jest, NodeJS, Express, Prisma, PostgreSQL, Docker." +
                "Experiência 3:" +
                "Líder de Setor Pleno | Cobasi | Curitiba, Brasil | Setembro 2017 - Janeiro 2024" +
                "Desenvolvimento de aplicativo e website para uso pessoal e da equipe." +
                "Responsável pelo setor com 70% do faturamento da loja. " +
                "Reconhecimento com troféu de cumprimento de metas entre mais de 300 lojas." +
                "Referência em organização e modelagem de processos." +
                "Alta média de retenção de talentos no setor." +
                "Tecnologias" +
                "Mobile Development: Flutter, Dart" +
                "Web Development: React, NextJS, TailwindCSS, Styled Components" +
                "Back-end: NodeJS, Express, Prisma, API REST, SQL, NoSQL" +
                "Banco de Dados: PostgreSQL, MongoDB, Firebase" +
                "Metodologias: Clean Architecture, TDD (Test Driven Development), SOLID" +
                "Testes: Unit Tests" +
                "Projetos" +
                "WorkTasks (2023 - 2024): Aplicativo mobile (Flutter) e web (NextJS, MongoDB, Firebase) para gestão de tarefas, precificação, impressão de etiquetas e controle de validade." +
                "Pet Family - E-Commerce (2023): E-commerce completo com painel administrativo, desenvolvido com React, NextJS, MongoDB, Firebase e TailwindCSS." +
                "Portfólio (2024): Website pessoal disponível em www.wagdev.com.br." +
                "Certificados" +
                "EFSET English Certificate - Score: 64/100, ADVANCED (CEFR C1) | Maio 2023" +
                "Idiomas: " +
                "Inglês: Nível profissional avançado " +
                "Português: Nativo " +
                "Interesses: Música, Inovação, Tecnologia, Artigos, Aprendizado Contínuo " +
                "Qualidades do Wagner baseadas no currículo: " +
                "Técnicas:" +
                "Full-stack: Conhecimento abrangente em desenvolvimento web e mobile, permitindo que ele trabalhe em diferentes partes de um projeto. " +
                "Metodologias Ágeis: Familiaridade com TDD e Clean Architecture, indicando foco em qualidade, organização e testes do código. " +
                "Aprendizado Rápido: Domínio de diversas tecnologias e constante busca por novos conhecimentos através de cursos e projetos. " +
                "Resolução de problemas: Experiência em identificar e solucionar problemas técnicos, melhorando a qualidade de sistemas existentes. " +
                "Comportamentais: " +
                "Liderança: Experiência comprovada em liderar equipes, motivar pessoas e atingir metas." +
                "Organização: Capacidade de organizar processos e manter a ordem, visível em seu histórico como líder de setor." +
                "Proatividade: Iniciativa para desenvolver projetos pessoais e buscar novas oportunidades de aprendizado. " +
                "Foco em resultados: Histórico de sucesso em alcançar metas e superar expectativas, demonstrado pelos reconhecimentos recebidos. " +
                "Outras qualidades: " +
                "Comunicação: O currículo bem estruturado e as informações adicionais sugerem boas habilidades de comunicação. " +
                "Inglês Avançado: Capacidade de trabalhar em projetos internacionais e acessar recursos técnicos em inglês. " +
                "No geral, Wagner se apresenta como um profissional dedicado, organizado e com grande potencial de crescimento. Suas habilidades técnicas e qualidades comportamentais o tornam um candidato valioso para diferentes tipos de projetos e equipes.",
            },
          ],
        },
        {
          role: "model",
          parts: [{ text: "Feliz em te conhecer. O que gostaria de saber?" }],
        },
      ];

      const chat = model.startChat({
        generationConfig,
        safetySettings,
        history: chatHistory,
      });

      const result = await chat.sendMessage(prompt);
      const response = result.response.text();
      setHistory((prevHistory) => [
        { parts: { text: response, role: "model" } },
        ...prevHistory,
      ]);
      setIsSending(false);
    }

    try {
      setHistory((prevHistory) => [
        { parts: { text: prompt, role: "user" } },
        ...prevHistory,
      ]);
      await runChat();
      setPrompt("");
    } catch (error) {
      setIsSending(false);
      Swal.fire({
        title: "There was an error sending the message. Please try again.",
        width: 400,
        padding: "3em",
        customClass: {
          popup:
            " p-2 flex justify-center items-center text-red-400 border-2 border-white font-tech text-4xl p-1 rounded-sm",
          title:
            "p-2 flex text-xl justify-center items-center text-red-400 border-2 border-red-400 font-tech text-4xl p-1 rounded-sm",
        },
        color: "#ffffff",
        background: "#ff444443",
        showConfirmButton: false,
        backdrop: `
          rgba(35, 17, 17, 0.676)
          left top
          no-repeat
        `,
      });
    }
  }

  return (
    <TabContainer>
      <div className="flex flex-col gap-2 h-full w-full">
        <h1 className="font-tech text-white text-2xl md:text-7xl">
          <Translator path="chatbot.title" />
        </h1>

        <div className="p-5 border-[1px] border-blue-200 h-full w-full overflow-y-scroll">
          <div className="flex flex-col-reverse justify-end gap-2">
            {history.map((item, index) =>
              item.parts.text === "" ? null : (
                <div
                  key={`_div${index}`}
                  className={`flex ${
                    item.parts.role === "user" ? "justify-end" : "justify-start"
                  } gap-1`}
                >
                  <Markdown
                    key={index}
                    className={`${
                      item.parts.role === "user"
                        ? " bg-blue-200/10 text-white"
                        : "left-0 bg-blue-200/30 text-white"
                    } p-2 rounded-md font-tech`}
                    remarkPlugins={[remarkGfm]}
                  >
                    {item.parts.text}
                  </Markdown>
                </div>
              )
            )}
          </div>
        </div>
        <input
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder={translationToString("chatbot.placeholder")}
          className=" p-5 border-[1px] border-blue-200 w-full bg-transparent font-tech text-white text-xl"
        ></input>
        <div className="mt-5 boxButtonLg">
          <button
            onClick={() => generateResponse(prompt)}
            className="flex transition-all text-lg h-11 bg-teal-200/20 lg:h-12 border-[1px]  border-teal-200/10 hover:text-white hover:shadow-lg hover:shadow-blue-200/40 hover:bg-blue-400/10 duration-700 justify-center items-center text-center text-blue-200 font-tech   py-2 px-4 w-full "
          >
            {isSending && (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="spin-up"
                fill="currentColor"
              >
                <path
                  d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                  opacity=".25"
                />
                <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
              </svg>
            )}
            {isSending && <Translator path="contact.sending" />}
            {!isSending && <Translator path="contact.send" />}
          </button>
        </div>
      </div>
    </TabContainer>
  );
};
