export default {
  translations: {
    home: {
      message: "Hello, world!",
    },
    container: {
      start: "Click to start",
    },
    navBar: {
      home: "Home",
      about: "About Me",
      technologies: "Technologies",
      projects: "Projects",
      contact: "Contact",
      talk: "Let's talk!",
    },
    about: {
      title: "About Me",
      description_p1:
        " As a tech lover, I dove headfirst into self-taught learning, creating" +
        " personal and professional projects to solve real problems in my work" +
        " environment. I started with Python projects, such as an" +
        " inventory tool and a task management application, before venturing" +
        " into web development with technologies like NextJS, MongoDB, and" +
        " Stripe, creating a fully functional e-commerce platform and a label" +
        " generation system, poster printing, managing items close to expiration" +
        " date, among other features. Additionally, I participated in various" +
        " courses and bootcamps, expanding my knowledge in technologies like" +
        " Flutter, React, and clean architecture.",
      description_p2:
        " In summary, I have demonstrated self-taught skills, the ability to creatively and effectively solve problems, and a strong determination to progress in a software development career. My journey so far reflects my commitment to continuous learning and my ability to face challenges with resilience and dedication.",
    },
    contact: {
      title: "Contact",
      name: "Name",
      namePlaceholder: "Your name..",
      email: "Email",
      emailPlaceholder: "Your email..",
      message: "Message",
      messagePlaceholder: "Your message..",
      send: "Send",
      sending: "Sending...",
      success: "Message received! Thank you!🚀",
      error: "There was an error sending the message. Please try again.",
    },
    projects: {
      descriptionTitle: "Description",
      functionalitiesTitle: "Functionalities",
      technologiesTitle: "Technologies",
      demoButton: "Demo",
      repositoryButton: "Repository",
    },
    chatbot: {
      title: "AI ChatBot",
      message:
        "Hello! I'm a IA Chatbot made by Wagner. How can I help you today?",
      placeholder: "Type your question here...",
    },
  },
};
