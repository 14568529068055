interface TabContainerProps {
  children: React.ReactNode;
}

export const TabContainer = ({ children }: TabContainerProps) => {
  return (
    <div className="p-2 lg:p-5  shadow scale-up boxContainer lg:mt-0 mt-[70px] flex justify-center items-center max-h-[68vh]  max-w-[70vw] lg:max-w-full  lg:max-h-full h-full w-full">
      {children}
    </div>
  );
};
