import { Link, useLocation } from "react-router-dom";
import useSound from "use-sound";
import clickSFX from "../../sounds/click-ui.mp3";
import hoverSFX from "../../sounds/hover-ui.mp3";
import { useEffect, useState } from "react";
import Card from "../Card";

interface ButtonProps {
  children: React.ReactNode;
  to: string;
  className?: string;
}

export const Button = ({ children, to, className }: ButtonProps) => {
  const [activePath, setActivePath] = useState<string>("");

  const location = useLocation();

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const [play] = useSound(clickSFX, { volume: 0.1 });
  const [playHover] = useSound(hoverSFX, { volume: 0.1 });
  return (
    <div className={`${className}`}>
      <Link
        to={to}
        onMouseOver={() => playHover()}
        onClick={() => play()}
        className={` ${
          activePath === to ? "text-white bg-blue-400/10" : "bg-teal-500/20"
        } flex transition-all text-lg h-11 lg:h-12 border-[1px]  border-teal-200/10 hover:text-white hover:shadow-lg hover:shadow-blue-200/40 hover:bg-blue-400/10 duration-700 justify-center items-center text-center text-blue-200 font-tech   py-2 px-4 w-full  `}
      >
        {children}
      </Link>
    </div>
  );
};
