export default {
  translations: {
    home: {
      message: "Olá, mundo!",
    },
    container: {
      start: "Clique para começar",
    },
    navBar: {
      home: "Início",
      about: "Sobre Mim",
      technologies: "Tecnologias",
      projects: "Projetos",
      contact: "Contato",
      talk: "Vamos conversar!",
    },
    about: {
      title: "Sobre Mim",
      description_p1:
        " Como amante de tecnologia, mergulhei de cabeça no aprendizado autodidata, criando projetos pessoais e profissionais para resolver problemas reais em meu ambiente de trabalho. Comecei com projetos em Python, como uma ferramenta de inventário e um aplicativo de gerenciamento de tarefas, antes de me aventurar no desenvolvimento web com tecnologias como NextJS, MongoDB e Stripe, criando uma plataforma de e-commerce totalmente funcional e um sistema de geração de etiquetas, impressão de cartazes, gerenciamento de itens próximos à data de validade, entre outros recursos. Além disso, participei de diversos cursos e bootcamps, expandindo meu conhecimento em tecnologias como Flutter, React e arquitetura limpa.",
      description_p2:
        " Em resumo, demonstrei habilidades autodidatas, a capacidade de resolver problemas de forma criativa e eficaz, e uma forte determinação para progredir em uma carreira de desenvolvimento de software. Minha jornada até agora reflete meu compromisso com a aprendizagem contínua e minha capacidade de enfrentar desafios com resiliência e dedicação.",
    },
    contact: {
      title: "Contato",
      name: "Nome",
      namePlaceholder: "Seu nome..",
      email: "Email",
      emailPlaceholder: "Seu email..",
      message: "Mensagem",
      messagePlaceholder: "Sua mensagem..",
      send: "Enviar",
      sending: "Enviando...",
      success: "Mensagem recebida! Obrigado!🚀",
      error: "Houve um erro ao enviar a mensagem. Por favor, tente novamente.",
    },
    projects: {
      descriptionTitle: "Descrição",
      functionalitiesTitle: "Funcionalidades",
      technologiesTitle: "Tecnologias",
      demoButton: "Demonstração",
      repositoryButton: "Repositório",
    },
    chatbot: {
      title: "ChatBot IA",
      message:
        "Olá! Sou um Chatbot IA feito por Wagner. Como posso ajudar você hoje?",
      placeholder: "Digite sua pergunta aqui...",
    },
  },
};
