import { useEffect } from "react";

interface Props {
  height?: number;
  width?: number;
  interval?: number;
}

export default function BinaryLoader(props: Props) {
  const { height, width, interval = 53 } = props;

  useEffect(() => {
    var canvas = document.getElementById("canvas")! as HTMLCanvasElement,
      ctx = canvas.getContext("2d")!;

    // Setting the width and height of the canvas
    canvas.width = width!;
    canvas.height = height!;

    // Setting up the letters
    var letters: any = "01";
    letters = letters.split("");

    // Setting up the columns
    var fontSize = 25,
      columns = canvas.width / fontSize + 100;

    // Setting up the drops
    var drops: any = [];
    for (var i = 0; i < columns; i++) {
      drops[i] = 2;
    }

    // Setting up the draw function
    function draw() {
      ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      for (var i = 0; i < drops.length; i++) {
        var text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillStyle = "#a994ff";
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);
        drops[i]++;
        if (interval !== 0) {
          if (drops[i] * fontSize > canvas.height && Math.random() > 0.95) {
            drops[i] = 0;
          }
        }
      }
    }
    let myInterval: any;
    if (interval !== 0) {
      myInterval = setInterval(draw, interval);
    }
    if (interval === 0) {
      drops = [];
      if (interval === 0) {
        drops = [];
        clearInterval(myInterval);
        canvas.style.opacity = "0";
      }
    }
  }, [width, height, interval]);

  return (
    <canvas
      id="canvas"
      className="fixed right-0 left-0 mx-auto z-10 mix-blend-screen pointer-events-none transition-all duration-1000 ease-in-out"
    ></canvas>
  );
}
