import { useEffect, useState } from "react";
import BinaryLoader from "../BinaryLoader/BinaryLoader";
import loadingSFX from "../../sounds/loading.mp3";
import useSound from "use-sound";
import { Translator } from "../i18n/Translator";

type ContainerProps = {
  children: React.ReactNode;
};

export const Container = ({ children }: ContainerProps) => {
  const [play] = useSound(loadingSFX, { volume: 0.1 });
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [interval, setInterval] = useState<number>(55);

  useEffect(() => {
    setHeight(document.getElementById("container-loader")?.clientHeight!);
    setWidth(document.getElementById("container-loader")?.clientWidth!);
  });

  const handleStart = () => {
    play();
    setIsStarted(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    setTimeout(() => {
      setInterval(0);
    }, 2000);
  }, []);

  return (
    <>
      {isStarted && (
        <div className="flex justify-center items-center h-full w-full scale-up-x-y sm:pt-2 sm:pb-2 sm:pr-4 sm:pl-4 ">
          {isLoading && height && width && (
            <BinaryLoader height={height} width={width} interval={interval} />
          )}
          <div className="outer-clip-border ">
            <div className="inside h-full w-full">
              <div
                id="container-loader"
                className="inner-shadow  w-full h-full"
              >
                <div className="flex box min-h-[80vh] lg:min-h-[80vh] min-w-[80vw] max-h-[80vh] lg:max-h-[80vh] max-w-[80vw]  h-full w-full ">
                  <div className="animate-in flex flex-1 background-gradient bg-slate-800/30  overflow-hidden">
                    <div className="light-bg-anim"></div>
                    <div className="p-5 lg:p-10  flex flex-1 flex-col b lg:flex-row background-pattern gap-4 ">
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isStarted && (
        <div className="h-full w-full flex justify-center items-center">
          <div className="boxButton ">
            <button
              onClick={() => handleStart()}
              className="block transition-all duration-700 shadow-lg shadow-teal-500/40  text-3xl text-center bg-teal-500/10 text-white font-tech  border-transparent  p-5 w-full"
            >
              <Translator path="container.start" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
