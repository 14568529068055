import { useRef, useState } from "react";
import { TabContainer } from "../TabContainer/TabContainer";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { Translator } from "../i18n/Translator";
import ReactDOMServer from "react-dom/server";

export const ContactTab = () => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const form = useRef(null);

  const translationToString = (path: string): string => {
    const tabContentString = ReactDOMServer.renderToString(
      <Translator path={path} />
    );
    return tabContentString;
  };

  const sendEmail = async (e: any) => {
    setIsSending(true);
    e.preventDefault();
    try {
      await emailjs
        .sendForm(
          "service_596xxlp",
          "template_aq8fr1y",
          form.current!,
          "wWUPhSl43Yd0iaRmp"
        )
        .then((result) => {
          Swal.fire({
            title: "Message received! Thank you!🚀",
            width: 400,
            padding: "3em",
            customClass: {
              popup:
                "outer-clip-border p-2 flex justify-center items-center text-blue-200 border-2 border-white font-tech text-4xl p-1 rounded-sm",
              title:
                "flex text-2xl justify-center items-center text-blue-200 border-2 border-white font-tech text-4xl p-1 rounded-sm",
            },
            color: "#ffffff",
            background: "#7f44ff43",
            showConfirmButton: false,
            backdrop: `
              rgba(0, 0, 0, 0.676)
              left top
              no-repeat
            `,
          });
        });
      e.target!.reset()!;
      setIsSending(false);
    } catch (error) {
      Swal.fire({
        title: "There was an error sending the message. Please try again.",
        width: 400,
        padding: "3em",
        customClass: {
          popup:
            " p-2 flex justify-center items-center text-red-400 border-2 border-white font-tech text-4xl p-1 rounded-sm",
          title:
            "p-2 flex text-xl justify-center items-center text-red-400 border-2 border-red-400 font-tech text-4xl p-1 rounded-sm",
        },
        color: "#ffffff",
        background: "#ff444443",
        showConfirmButton: false,
        backdrop: `
          rgba(35, 17, 17, 0.676)
          left top
          no-repeat
        `,
      });
      setIsSending(false);
    }
  };

  const tabContent = (
    <TabContainer>
      <div className="p-2 lg:p-5 h-full w-full flex flex-col">
        <h1 className="font-tech text-4xl text-blue-200">
          <Translator path="contact.title" />
        </h1>
        <form ref={form} onSubmit={sendEmail} className="flex flex-col h-full">
          <label className="mt-5 font-tech text-blue-200" htmlFor="name">
            <Translator path="contact.name" />
          </label>
          <input
            className="p-1 px-2 rounded-md text-white bg-blue-950/60 border-blue-200/30 border-[1px]"
            type="text"
            id="name"
            name="name"
            placeholder={translationToString("contact.namePlaceholder")}
            required
          />
          <label className="font-tech text-blue-200" htmlFor="email">
            <Translator path="contact.email" />
          </label>
          <input
            className="p-1 px-2 rounded-md bg-blue-950/60 text-white border-blue-200/30 border-[1px]"
            type="text"
            id="email"
            name="email"
            placeholder={translationToString("contact.emailPlaceholder")}
            required
          />
          <label className="font-tech text-blue-200" htmlFor="message">
            <Translator path="contact.message" />
          </label>
          <textarea
            className="p-1 px-2 rounded-md h-full text-white bg-blue-950/60 border-blue-200/30 border-[1px]"
            id="message"
            name="message"
            placeholder={translationToString("contact.messagePlaceholder")}
            required
          />
          <div className="mt-5 boxButton">
            <button
              type="submit"
              className="flex justify-center items-center gap-2 text-center text-blue-200 font-tech mix-blend-multiply border-transparent  py-2 px-4 w-full transition-all duration-1000 ease-in-out bg-blue-200/10"
            >
              {isSending && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="spin-up"
                  fill="currentColor"
                >
                  <path
                    d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                    opacity=".25"
                  />
                  <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
                </svg>
              )}
              {isSending && <Translator path="contact.sending" />}
              {!isSending && <Translator path="contact.send" />}
            </button>
          </div>
        </form>
      </div>
    </TabContainer>
  );

  return tabContent;
};
