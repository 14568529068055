import { useTranslation } from "react-i18next";
import { BrazilFlag } from "./BrazilFlag";
import { EuaFlag } from "./EuaFlag";

export const I18n = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const selectedLanguage = i18n.language;

  return (
    <div className="flex gap-2">
      <div
        className={`p-2 boxButton ${
          selectedLanguage === "pt-BR" ? "bg-teal-500/20" : ""
        }`}
      >
        <BrazilFlag
          isSelected={selectedLanguage === "pt-BR"}
          onClick={() => changeLanguage("pt-BR")}
        />
      </div>
      <div
        className={`p-2 boxButton ${
          selectedLanguage === "en-US" ? "bg-teal-500/20" : ""
        }`}
      >
        <EuaFlag
          isSelected={selectedLanguage === "en-US"}
          onClick={() => changeLanguage("en-US")}
        />
      </div>
    </div>
  );
};
