import { TabContainer } from "../TabContainer/TabContainer";
import GlitchClip from "react-glitch-effect/core/GlitchClip";
import GlitchText from "react-glitch-effect/core/GlitchText";
import Card from "../Card";
import { Translator } from "../i18n/Translator";
import { I18n } from "../i18n/i18n";

export const HomeTab = () => {
  return (
    <TabContainer>
      <div className="flex flex-col gap-2 justify-center items-center w-full h-full">
        <I18n />
        <h1 className="font-tech text-white text-4xl">
          <Translator path="home.message" />
        </h1>
        <GlitchClip>
          <GlitchText component="h1">
            <h1
              data-text="<Wagner_Araujo_Ramos/>"
              className="boxButtonLg p-2 lg:text-7xl text-lg font-tech w-full text-white/90"
            >
              &lt;Wagner_Araujo_Ramos/&gt;
            </h1>
          </GlitchText>
        </GlitchClip>
        <GlitchClip>
          <GlitchText component="h2">
            <h2
              data-text="Software_Engineer"
              className="lg:text-4xl text-lg font-tech text-white/90"
            >
              Software_Engineer
            </h2>
          </GlitchText>
        </GlitchClip>
      </div>
    </TabContainer>
  );
};
