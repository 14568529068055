import { Skill } from "../../../mocks/SkillMocks";
import { SkillProgressBar } from "../SkillProgressBar";

interface SkillBoxProps {
  skill: Skill;
}

export const SkillBox = ({ skill }: SkillBoxProps) => {
  return (
    <div className="p-5 boxButton aspect-[16/8] flex gap-2 transition-all duration-500 hover:bg-blue-200/20  rounded-md">
      <div className="flex items-center">
        <div
          className="opacity-80"
          dangerouslySetInnerHTML={{ __html: skill.icon }}
        />
      </div>
      <div className="flex flex-col justify-center gap-2">
        <h2 className="text-xl font-tech text-blue-200">{skill.name}</h2>
        <div className="flex justify-center items-center gap-2">
          <span className="font-tech font-bold text-blue-200 text-xl">EXP</span>
          <SkillProgressBar progress={skill.progress} />
        </div>
      </div>

      {/* <div className="flex items-center gap-1">
        <span className="font-tech font-bold text-blue-200 text-xl">
          PROJECTS
        </span>
        <span className="p-1 border-[1px] rounded-md border-blue-200 font-tech font-bold text-blue-200 text-base h-3">
          5
        </span>
      </div> */}
    </div>
  );
};
