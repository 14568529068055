import { useTranslation } from "react-i18next";
import {
  PortfolioMocks,
  PortfolioMocksPtBR,
  PortofolioItem,
} from "../../mocks/PortfolioMocks";
import { TabContainer } from "../TabContainer/TabContainer";
import { ProjectsBox } from "./ProjectsBox";
import { useEffect, useState } from "react";

export const ProjectsTab = () => {
  const [mocks, setMocks] = useState<PortofolioItem[]>();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "pt-BR") {
      setMocks(PortfolioMocksPtBR);
    } else {
      setMocks(PortfolioMocks);
    }
  }, []);

  return (
    <TabContainer>
      <div className="projects-scroll flex flex-col h-full w-full overflow-y-scroll">
        {mocks &&
          mocks.map((item: PortofolioItem) => (
            <ProjectsBox key={item.id} item={item} />
          ))}
      </div>
    </TabContainer>
  );
};
