export interface PortofolioItem {
  id: number;
  title: string;
  description: string;
  functionalities: string[];
  images: string[];
  repo: string | undefined;
  demo: string | undefined;
  technologies: string[];
}

export const PortfolioMocks: PortofolioItem[] = [
  {
    id: 0,
    title: "WorkTasks Web",
    description:
      "This system streamlines the creation of labels and posters for printing, efficiently manages items nearing expiration, and oversees the task management of my subordinates. This initiative significantly contributed to enhancing team performance and improving overall results delivery.",
    functionalities: [
      "Separate login system for each store and department",
      "Kanban-style task management",
      "Bulletin board",
      "Real-time department chat with RealtimeDatabase",
      "Generation of labels for printing, such as: Items nearing expiration, transfer items, items with interest-free/interest-bearing installment",
      "Generation and printing of personalized promotion posters, buy one get one free, buy one get one half off, discount on the second unit, etc. All adapted to the company's pre-printed papers",
      "Sharing of posters according to offer validity between stores (Previously each store had to make their own via manual Excel and some shared among themselves)",
      "Management system for creating, editing, and sharing offers to be printed",
      "Management of items nearing expiration, product internal code lookup (I obtained a spreadsheet containing code and description, created a script that ran the spreadsheet and added everything to MongoDB for later use, as I had no access to the company's system)",
      "Admin system for managing system users, as well as querying completed tasks, tasks in progress, tasks within deadline, pending tasks, etc. by user.",
    ],
    images: [
      "/images/worktasks-web/worktasks-web.png",
      "/images/worktasks-web/worktasks-web(1).png",
      "/images/worktasks-web/worktasks-web(2).png",
      "/images/worktasks-web/worktasks-web(3).png",
      "/images/worktasks-web/worktasks-web(4).png",
      "/images/worktasks-web/worktasks-web(5).png",
      "/images/worktasks-web/worktasks-web(6).png",
      "/images/worktasks-web/worktasks-web(7).png",
      "/images/worktasks-web/worktasks-web(8).png",
      "/images/worktasks-web/worktasks-web(9).png",
      "/images/worktasks-web/worktasks-web(10).png",
      "/images/worktasks-web/worktasks-web(11).png",
    ],
    repo: undefined,
    demo: "https://worktasks-web.vercel.app",
    technologies: [
      "NextJS",
      "Javascript",
      "TailwindCSS",
      "MongoDB",
      "Firebase",
    ],
  },
  {
    id: 1,
    title: "WoksTasks Mobile",
    description:
      "An app designed to organize and distribute tasks, as well as assist in the pricing and validity process at the company where I work, with a login system, user registration, and sector integration using Firebase.",
    functionalities: [
      "Separate login system for each store and department",
      "Kanban-style task management",
      "EAN code scanner for item pricing",
      "Management of items nearing expiration, product internal code lookup (I obtained a spreadsheet containing code and description, created a script that ran the spreadsheet and added everything to MongoDB for later use, as I had no access to the company's system)",
    ],
    images: [
      "/images/worktasks-mobile/worktasks-mobile(1).jpg",
      "/images/worktasks-mobile/worktasks-mobile(2).jpg",
      "/images/worktasks-mobile/worktasks-mobile(3).jpg",
      "/images/worktasks-mobile/worktasks-mobile(4).jpg",
      "/images/worktasks-mobile/worktasks-mobile(5).jpg",
      "/images/worktasks-mobile/worktasks-mobile(6).jpg",
      "/images/worktasks-mobile/worktasks-mobile(7).jpg",
      "/images/worktasks-mobile/worktasks-mobile(8).jpg",
    ],
    repo: "https://github.com/WagDevX/kivy_venv",
    demo: undefined,
    technologies: ["Python", "Firebase", "KivyMD", "Kivy"],
  },
  {
    id: 2,
    title: "Cloud Files - Front End",
    description:
      "Inspired on Google Drive, i've built a full stack file, folder management with jwt based authentication login, register.",
    functionalities: [
      "Create, delete, update, download files and folders",
      "Login, Register",
    ],
    images: [
      "/images/cloudfiles/cloudfiles(1).png",
      "/images/cloudfiles/cloudfiles(2).png",
      "/images/cloudfiles/cloudfiles(3).png",
      "/images/cloudfiles/cloudfiles(4).png",
      "/images/cloudfiles/cloudfiles(5).png",
    ],
    repo: "https://github.com/WagDevX/front-end-cloud-files",
    demo: "",
    technologies: ["React", "Tailwind", "Axios", "Typescript", "JWT"],
  },
  {
    id: 3,
    title: "Cloud Files - Back End",
    description:
      "Inspired on Google Drive, i've built a full stack file, folder management with jwt based authentication login, register, permissions.",
    functionalities: [
      "Create, delete, update, download files and folders",
      "Login, Register, Forgot Password",
      "Permissions",
    ],
    images: [
      "/images/cloudfiles/cloudfiles(1).png",
      "/images/cloudfiles/cloudfiles(2).png",
      "/images/cloudfiles/cloudfiles(3).png",
      "/images/cloudfiles/cloudfiles(4).png",
      "/images/cloudfiles/cloudfiles(5).png",
    ],
    repo: "https://github.com/WagDevX/NodeExpressApi",
    demo: "",
    technologies: [
      "Clean Architecture",
      "NodeJS",
      "Express",
      "Docker",
      "Typescript",
      "Jest",
      "PostgreSQL",
      "AWS",
    ],
  },
  {
    id: 4,
    title: "Todo App",
    description:
      "Inpired on Google Keep, i've built a fullstack To-Do App with Next.js 14 and ServerActions integrated with Mongo Database.",
    functionalities: [
      "Favorite",
      "Mobile First",
      "Color Picker",
      "Skeleton Loading",
      "Accessibility",
      "Search",
      "Filter by Favorite",
    ],
    images: [
      "/images/todoapp/todoapp.png",
      "/images/todoapp/todoapp(1).png",
      "/images/todoapp/todoapp(2).png",
    ],
    repo: "https://github.com/WagDevX/to-do-fullstack-next14",
    demo: "https://to-do-front-end-next.vercel.app/",
    technologies: ["React", "TypeScript", "TailwindCSS"],
  },
  {
    id: 5,
    title: "Petfamily e-commerce",
    description:
      "A fully functional e-commerce with admin system to add items, categories, change banner, manage sales etc.",
    functionalities: [
      "Stripe Checkout",
      "Admin System",
      "Elegant Design",
      "Productg Reviews",
      "Cart",
      "Social Login",
    ],
    images: [
      "/images/petfamily/petfamily(1).png",
      "/images/petfamily/petfamily(2).png",
      "/images/petfamily/petfamily(3).png",
      "/images/petfamily/petfamily(4).png",
    ],
    repo: "https://github.com/WagDevX/pet-family-ecommerce-front",
    demo: "https://pet-family-ecommerce-front.vercel.app/",
    technologies: [
      "NextJS",
      "Javascript",
      "TailwindCSS",
      "Styled Components",
      "MongoDB",
      "Vercel",
      "Stripe",
    ],
  },
  {
    id: 6,
    title: "Admin Petfamily e-commerce",
    description:
      "A fully functional e-commerce with admin system to add items, categories, change banner, manage sales etc.",
    functionalities: [
      "Stripe Checkout",
      "Admin System",
      "Elegant Design",
      "Productg Reviews",
      "Cart",
      "Social Login",
    ],
    images: [
      "/images/petfamily-admin/admin(1).png",
      "/images/petfamily-admin/admin(2).png",
      "/images/petfamily-admin/admin(3).png",
      "/images/petfamily-admin/admin(4).png",
      "/images/petfamily-admin/admin(5).png",
      "/images/petfamily-admin/admin(6).png",
    ],
    repo: "https://github.com/WagDevX/pet-family-ecommerce-front",
    demo: "https://pet-family-ecommerce-front.vercel.app/",
    technologies: [
      "NextJS",
      "Javascript",
      "TailwindCSS",
      "Styled Components",
      "MongoDB",
      "Vercel",
      "Stripe",
    ],
  },
  {
    id: 7,
    title: "Contacts Flutter App",
    description:
      "An app that connects to Back4App APi to store data from contacts. Challenge project for the BootCamp Santander 2023 - Mobile development with Flutter..",
    functionalities: [
      "Create, Read and Update Contacts",
      "Dark Light Mode",
      "Languague Change",
      "Camera Access",
      "Gallery Access",
      "Crop Image",
      "Custom Animations",
    ],
    images: [
      "/images/fluttercontacts/flutter-contacts(1).jpg",
      "/images/fluttercontacts/flutter-contacts(2).jpg",
      "/images/fluttercontacts/flutter-contacts(3).jpg",
      "/images/fluttercontacts/flutter-contacts(4).jpg",
      "/images/fluttercontacts/flutter-contacts(5).jpg",
      "/images/fluttercontacts/flutter-contacts(6).jpg",
      "/images/fluttercontacts/flutter-contacts(7).jpg",
      "/images/fluttercontacts/flutter-contacts(8).jpg",
      "/images/fluttercontacts/flutter-contacts(9).jpg",
    ],
    repo: "https://github.com/WagDevX/contacts",
    demo: "",
    technologies: ["Flutter", "Backend4App", "Dart", "MVC"],
  },
  {
    id: 8,
    title: "GRA Landing Page",
    description: "A simple landing page for a automation company.",
    functionalities: [
      "Dark and Light Mode",
      "Responsiveness",
      "Simple",
      "Quote PDF Generator",
    ],
    images: [
      "/images/gra-landing/gralanding(1).png",
      "/images/gra-landing/gralanding(2).png",
      "/images/gra-landing/gralanding(3).png",
      "/images/gra-landing/gralanding(4).png",
      "/images/gra-landing/gralanding(5).png",
    ],
    repo: "https://github.com/WagDevX/gra-lading-page",
    demo: "https://gra-sepia.vercel.app/",
    technologies: ["Astro", "Typescript", "React", "TailwindCSS"],
  },
  {
    id: 9,
    title: "Portfolio V1",
    description:
      "A portfolio website built with React and Node, aimed at showcasing my projects and attracting job opportunities and clients.",
    functionalities: ["Internationlization", "Responsiveness", "Animations"],
    images: [
      "/images/portfolio1/portfolio1(1).png",
      "/images/portfolio1/portfolio1(2).png",
      "/images/portfolio1/portfolio1(3).png",
      "/images/portfolio1/portfolio1(4).png",
    ],
    repo: "https://github.com/WagDevX/meu-site-portfolio",
    demo: "https://wagner-portfolio.web.app/",
    technologies: ["React", "Javascript", "TailwindCSS"],
  },
  {
    id: 10,
    title: "Portfolio V2",
    description:
      "Second version of my portfolio website built with React, aimed at showcasing my projects and attracting job opportunities and clients.",
    functionalities: [
      "Internationlization",
      "Responsiveness",
      "Animations",
      "Sound Effects",
    ],
    images: [
      "/images/portfolio2/portfolio2(1).png",
      "/images/portfolio2/portfolio2(2).png",
    ],
    repo: undefined,
    demo: "https://wagdev.com.br",
    technologies: ["React", "TypeScript", "TailwindCSS", "CSS Vannila"],
  },
];

export const PortfolioMocksPtBR: PortofolioItem[] = [
  {
    id: 0,
    title: PortfolioMocks[0].title,
    description:
      "Este sistema simplifica a criação de etiquetas e cartazes para impressão, gerencia eficientemente itens próximos à data de validade e supervisiona o gerenciamento de tarefas de meus subordinados. Esta iniciativa contribuiu significativamente para aprimorar o desempenho da equipe e melhorar a entrega de resultados em geral.",
    functionalities: [
      "Sistema de login separado para cada loja e departamento",
      "Gerenciamento de tarefas estilo Kanban",
      "Quadro de avisos",
      "Chat em tempo real do departamento com RealtimeDatabase",
      "Geração de etiquetas para impressão, como: Itens próximos à data de validade, itens de transferência, itens com parcelamento sem juros/juros",
      "Geração e impressão de cartazes de promoção personalizados, compre um e leve outro, compre um e leve o segundo pela metade do preço, desconto na segunda unidade, etc. Tudo adaptado aos papéis pré-impressos da empresa",
      "Compartilhamento de cartazes de acordo com a validade da oferta entre as lojas (Anteriormente cada loja tinha que fazer o seu próprio via Excel manual e alguns compartilhavam entre si)",
      "Sistema de gerenciamento para criar, editar e compartilhar ofertas a serem impressas",
      "Gerenciamento de itens próximos à data de validade, pesquisa de código interno do produto (Obtive uma planilha contendo código e descrição, criei um script que rodava a planilha e adicionava tudo ao MongoDB para uso posterior, pois não tinha acesso ao sistema da empresa)",
      "Sistema de administração para gerenciar usuários do sistema, bem como consultar tarefas concluídas, tarefas em andamento, tarefas dentro do prazo, tarefas pendentes, etc. por usuário.",
    ],
    technologies: PortfolioMocks[0].technologies ?? [""],
    images: PortfolioMocks[0].images ?? [""],
    repo: PortfolioMocks[0].repo ?? undefined,
    demo: PortfolioMocks[0].demo ?? undefined,
  },
  {
    id: 1,
    title: PortfolioMocks[1].title,
    description:
      "Um aplicativo projetado para organizar e distribuir tarefas, bem como auxiliar no processo de precificação e validade na empresa onde trabalho, com um sistema de login, registro de usuário e integração de setor usando Firebase.",
    functionalities: [
      "Sistema de login separado para cada loja e departamento",
      "Gerenciamento de tarefas estilo Kanban",
      "Scanner de código EAN para precificação de itens",
      "Gerenciamento de itens próximos à data de validade, pesquisa de código interno do produto (Obtive uma planilha contendo código e descrição, criei um script que rodava a planilha e adicionava tudo ao MongoDB para uso posterior, pois não tinha acesso ao sistema da empresa)",
    ],
    technologies: PortfolioMocks[1].technologies ?? [""],
    images: PortfolioMocks[1].images ?? [""],
    repo: PortfolioMocks[1].repo ?? undefined,
    demo: PortfolioMocks[1].demo ?? undefined,
  },
  {
    id: 2,
    title: PortfolioMocks[2].title,
    description:
      "Inspirado no Google Drive, construí um sistema de gerenciamento de arquivos e pastas full stack com login de autenticação baseado em jwt, registro.",
    functionalities: [
      "Criar, excluir, atualizar, baixar arquivos e pastas",
      "Login, Registrar",
    ],
    technologies: PortfolioMocks[2].technologies ?? [""],
    images: PortfolioMocks[2].images ?? [""],
    repo: PortfolioMocks[2].repo ?? undefined,
    demo: PortfolioMocks[2].demo ?? undefined,
  },
  {
    id: 3,
    title: PortfolioMocks[3].title,
    description:
      "Inspirado no Google Drive, construí um sistema de gerenciamento de arquivos e pastas full stack com login de autenticação baseado em jwt, registro, permissões.",
    functionalities: [
      "Criar, excluir, atualizar, baixar arquivos e pastas",
      "Login, Registrar, Esqueci a senha",
      "Permissões",
    ],
    technologies: PortfolioMocks[3].technologies ?? [""],
    images: PortfolioMocks[3].images ?? [""],
    repo: PortfolioMocks[3].repo ?? undefined,
    demo: PortfolioMocks[3].demo ?? undefined,
  },
  {
    id: 4,
    title: PortfolioMocks[4].title,
    description:
      "Inspirado no Google Keep, construí um aplicativo To-Do App fullstack com Next.js 14 e ServerActions integrado com Banco de Dados Mongo.",
    functionalities: [
      "Favoritar",
      "Mobile First",
      "Seletor de Cores",
      "Carregamento de Esqueleto",
      "Acessibilidade",
      "Pesquisa",
      "Filtrar por Favorito",
    ],
    technologies: PortfolioMocks[4].technologies ?? [""],
    images: PortfolioMocks[4].images ?? [""],
    repo: PortfolioMocks[4].repo ?? undefined,
    demo: PortfolioMocks[4].demo ?? undefined,
  },
  {
    id: 5,
    title: PortfolioMocks[5].title,
    description:
      "Um e-commerce totalmente funcional com sistema de administração para adicionar itens, categorias, alterar banner, gerenciar vendas etc.",
    functionalities: [
      "Checkout Stripe",
      "Sistema Admin",
      "Design Elegante",
      "Avaliações de Produtos",
      "Carrinho",
      "Login Social",
    ],
    technologies: PortfolioMocks[5].technologies ?? [""],
    images: PortfolioMocks[5].images ?? [""],
    repo: PortfolioMocks[5].repo ?? undefined,
    demo: PortfolioMocks[5].demo ?? undefined,
  },
  {
    id: 6,
    title: PortfolioMocks[6].title,
    description:
      "Um e-commerce totalmente funcional com sistema de administração para adicionar itens, categorias, alterar banner, gerenciar vendas etc.",
    functionalities: [
      "Checkout Stripe",
      "Sistema Admin",
      "Design Elegante",
      "Avaliações de Produtos",
      "Carrinho",
      "Login Social",
    ],
    technologies: PortfolioMocks[6].technologies ?? [""],
    images: PortfolioMocks[6].images ?? [""],
    repo: PortfolioMocks[6].repo ?? undefined,
    demo: PortfolioMocks[6].demo ?? undefined,
  },
  {
    id: 7,
    title: PortfolioMocks[7].title,
    description:
      "Um aplicativo que se conecta a API Back4App para armazenar dados de contatos. Projeto desafio para o BootCamp Santander 2023 - Desenvolvimento Mobile com Flutter.",
    functionalities: [
      "Criar, Ler e Atualizar Contatos",
      "Modo Escuro Claro",
      "Mudança de Idioma",
      "Acesso à Câmera",
      "Acesso à Galeria",
      "Recortar Imagem",
      "Animações Personalizadas",
    ],
    technologies: PortfolioMocks[7].technologies ?? [""],
    images: PortfolioMocks[7].images ?? [""],
    repo: PortfolioMocks[7].repo ?? undefined,
    demo: PortfolioMocks[7].demo ?? undefined,
  },
  {
    id: 8,
    title: PortfolioMocks[8].title,
    description: "Uma simples landing page para uma empresa de automação.",
    functionalities: [
      "Modo Escuro e Claro",
      "Responsividade",
      "Simples",
      "Gerador de PDF de Cotação",
    ],
    technologies: PortfolioMocks[8].technologies ?? [""],
    images: PortfolioMocks[8].images ?? [""],
    repo: PortfolioMocks[8].repo ?? undefined,
    demo: PortfolioMocks[8].demo ?? undefined,
  },
  {
    id: 9,
    title: PortfolioMocks[9].title,
    description:
      "Um site de portfólio construído com React e Node, com o objetivo de mostrar meus projetos e atrair oportunidades e clientes.",
    functionalities: ["Internacionalização", "Responsividade", "Animações"],
    technologies: PortfolioMocks[9].technologies ?? [""],
    images: PortfolioMocks[9].images ?? [""],
    repo: PortfolioMocks[9].repo ?? undefined,
    demo: PortfolioMocks[9].demo ?? undefined,
  },
  {
    id: 10,
    title: PortfolioMocks[10].title,
    description:
      "Segunda versão do meu site de portfólio construído com React, com o objetivo de mostrar meus projetos e atrair oportunidades  e clientes.",
    functionalities: [
      "Internacionalização",
      "Responsividade",
      "Animações",
      "Efeitos Sonoros",
    ],
    technologies: PortfolioMocks[10].technologies ?? [""],
    images: PortfolioMocks[10].images ?? [""],
    repo: PortfolioMocks[10].repo ?? undefined,
    demo: PortfolioMocks[10].demo ?? undefined,
  },
];
