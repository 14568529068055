import { Outlet, useLocation } from "react-router-dom";
import { NavBar } from "../components/NavBar/NavBar";
import { Container } from "../components/Container/Container";
import { useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import clickSFX from "../sounds/click-ui.mp3";
import { BurguerSVG } from "../components/HomeTab/BurguerSVG";
import { IconLinkedin } from "../components/HomeTab/LikedingSVG";
import { IconGithub } from "../components/HomeTab/GitHubSVG";
import { IconSquareTwitter } from "../components/HomeTab/TwitterSVG";
import Starfield from "react-starfield";

export default function Home() {
  const [showNav, setShowNav] = useState<boolean>(false);
  const [activePath, setActivePath] = useState<string>("");
  const [playHover] = useSound(clickSFX, { volume: 0.1 });
  const location = useLocation();

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  useEffect(() => {
    setShowNav(false);
    const svgatorObject = document.getElementById("reverse") as any;
    if (svgatorObject) {
      svgatorObject.beginElement();
    }
  }, [activePath]);

  const handleSetShouNav = () => {
    setShowNav(!showNav);
    playHover();
  };

  return (
    <div className="flex justify-center bg-black/75 h-full ">
      <Starfield
        starCount={500}
        starColor={[255, 255, 255]}
        speedFactor={0.2}
        backgroundColor="black"
      />
      <div className="min-h-screen mx-0">
        <Container>
          <div className="lg:hidden p-1 px-2 flex scale-up-y justify-between bg-blue-200/20 border-blue-200 border-r-2 border-l-2 overflow-hidden">
            <button
              onClick={() => {
                handleSetShouNav();
              }}
              className="lg:hidden"
            >
              <BurguerSVG />
            </button>
            <button>
              <IconLinkedin />
            </button>
            <button>
              <IconGithub />
            </button>
            <button>
              <IconSquareTwitter />
            </button>
          </div>

          <NavBar isVisible={showNav} />
          <div className={`${showNav ? "hidden" : "h-full w-full"}`}>
            <Outlet />
          </div>
        </Container>
      </div>
    </div>
  );
}
