import React, { useEffect, useRef } from "react";

interface CardProps {
  children: React.ReactNode;
}

export default function Card({ children }: CardProps) {
  let bounds;
  const inputRef = useRef(null);
  const glowRef = useRef(null);
  const rotateToMouse = (e: any) => {
    bounds = (inputRef.current! as HTMLElement).getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2,
    };
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    (inputRef.current! as HTMLElement).style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
      ${center.y / 100},
      ${-center.x / 100},
      0,
      ${Math.log(distance) * 2}deg
      )
    `;
    console.log(center.y / 100);
    (glowRef.current! as HTMLElement).style.backgroundImage = `
      radial-gradient(
        circle at
        ${center.x * 2 + bounds.width / 2}px
        ${center.y * 2 + bounds.height / 2}px,
        #b1bdff52,
        #00000030
      )
    `;
  };
  const removeListener = (e: any) => {
    (inputRef.current! as HTMLElement).style.transform = "";
    (inputRef.current! as HTMLElement).style.background = "";
  };
  useEffect(() => {});
  return (
    <div className="app h-full w-full ">
      <div
        ref={inputRef}
        className="card"
        onMouseLeave={removeListener}
        onMouseMove={rotateToMouse}
      >
        {children}
        <div ref={glowRef} className="glow" />
      </div>
    </div>
  );
}
