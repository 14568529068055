import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./routes/error-page";
import Home from "./routes/home";
import { HomeTab } from "./components/HomeTab/HomeTab";
import { AboutTab } from "./components/AboutTab/AboutTab";
import { TechnologiesTab } from "./components/SkillsTab/TechnologiesTab";
import { ProjectsTab } from "./components/ProjectsTab/ProjectsTab";
import { ContactTab } from "./components/ContactTab/ContactTab";
import { ChatBot } from "./components/ChatBot";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomeTab />,
      },
      {
        path: "/about",
        element: <AboutTab />,
      },
      {
        path: "/technologies",
        element: <TechnologiesTab />,
      },
      {
        path: "/projects",
        element: <ProjectsTab />,
      },
      {
        path: "/contact",
        element: <ContactTab />,
      },
      {
        path: "/chatbot",
        element: <ChatBot />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
