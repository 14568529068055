import { Button } from "../Button/Button";
import Lottie from "react-lottie";
import animationData from "../../lotties/loading.json";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Card from "../Card";
import { Trans } from "react-i18next";
import { Translator } from "../i18n/Translator";

interface NavBarProps {
  isVisible: boolean;
}

export const NavBar = ({ isVisible }: NavBarProps) => {
  const [activePath, setActivePath] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <nav
      className={` ${
        isVisible ? "" : "hidden"
      } scale-up  w-full min-w-0 min-h-0 max-w-[90vw] mt-20 pb-3 lg:pb-0 lg:mt-0 h-full max-h-[85vh] lg:block lg:min-w-[15vw] lg:max-w-[15vw]`}
    >
      <ul className="flex flex-col justify-between h-full">
        <li>
          <Link to="/chatbot">
            <Card>
              <div className=" boxContainer flex flex-col h-[25vh] justify-center items-center border-[1px] border-teal-500/20">
                {/* <DotLottieReact
              data={animationData}
              loop
              autoplay
              autoResizeCanvas
              renderConfig={{ devicePixelRatio: 4 }}
            /> */}
                <Lottie options={defaultOptions} height={200} width={200} />
                <p className="mt-1 mb-2 font-tech text-blue-200">
                  {" "}
                  <Translator path="navBar.talk" />{" "}
                </p>
              </div>
            </Card>
          </Link>
        </li>

        <li>
          <Button
            className={`${
              activePath === "/"
                ? "boxButtonSelected  shadow-md shadow-blue-200/40"
                : "boxButton"
            }`}
            to="/"
          >
            <Translator path="navBar.home" />
          </Button>
        </li>
        <li>
          <Button
            className={`${
              activePath === "/about"
                ? "boxButtonSelected  shadow-md shadow-blue-200/40"
                : "boxButton"
            }`}
            to="/about"
          >
            <Translator path="navBar.about" />
          </Button>
        </li>
        <li>
          <Button
            className={`${
              activePath === "/technologies"
                ? "boxButtonSelected  shadow-md shadow-blue-200/40"
                : "boxButton"
            }`}
            to="/technologies"
          >
            <Translator path="navBar.technologies" />
          </Button>
        </li>
        <li>
          <Button
            className={`${
              activePath === "/projects"
                ? "boxButtonSelected  shadow-md shadow-blue-200/40"
                : "boxButton"
            }`}
            to="/projects"
          >
            <Translator path="navBar.projects" />
          </Button>
        </li>
        <li>
          <Button
            className={`${
              activePath === "/contact"
                ? "boxButtonSelected text-white shadow-md shadow-blue-200/40"
                : "boxButton"
            }`}
            to="/contact"
          >
            <Translator path="navBar.contact" />
          </Button>
        </li>
      </ul>
    </nav>
  );
};
