import { SkillMocks } from "../../mocks/SkillMocks";
import { TabContainer } from "../TabContainer/TabContainer";
import { SkillBox } from "./SkillBox";

export const TechnologiesTab = () => {
  return (
    <TabContainer>
      <div className="w-full p-3 max-h-full grid grid-cols-1 lg:grid-cols-3 gap-4 overflow-y-auto">
        {SkillMocks.map((skill) => (
          <SkillBox key={skill.id} skill={skill} />
        ))}
      </div>
    </TabContainer>
  );
};
