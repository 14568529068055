import { PortofolioItem } from "../../../mocks/PortfolioMocks";
import { Button } from "../../Button/Button";
import { Translator } from "../../i18n/Translator";

interface ProjectsBoxProps {
  item: PortofolioItem;
}

export const ProjectsBox = ({ item }: ProjectsBoxProps) => {
  return (
    <div className="grid p-3 gap-2 pb-10 justify-center w-full items-center text-start">
      <h1 className="font-tech text-2xl lg:text-4xl text-white">
        {item.title}
      </h1>
      <div className="flex justify-center items-center">
        <div className="p-1 grid max-w-full w-full h-full max-h-[75vh] border-[1px] border-blue-200/50 rounded-md">
          <swiper-container slidesPerView="auto" zoom>
            {item.images.map((image) => (
              <swiper-slide>
                <img src={image} alt={item.title} height={100} width={100} />
              </swiper-slide>
            ))}
          </swiper-container>
        </div>
      </div>

      <label className="font-tech text-white border-b-[1px] " htmlFor="">
        <Translator path="projects.descriptionTitle" />
      </label>
      <p className="font-tech text-white/50 text-base">{item.description}</p>

      <label className="font-tech text-white border-b-[1px]" htmlFor="">
        <Translator path="projects.functionalitiesTitle" />
      </label>
      <div className="flex justify-start">
        <ul className="w-fit text-left">
          {item.functionalities.map((func) => (
            <li
              key={`_func${item.id}`}
              className="font-tech text-white/50 text-base"
            >
              {func}
            </li>
          ))}
        </ul>
      </div>

      <label className="font-tech text-white border-b-[1px]" htmlFor="">
        <Translator path="projects.technologiesTitle" />
      </label>
      <ul className="flex flex-wrap justify-start gap-4">
        {item.technologies &&
          item.technologies.map((tech) => (
            <li
              key={`_tech${item.id}`}
              className="font-tech text-white/50 text-base"
            >
              {tech}
            </li>
          ))}
      </ul>
      <label className="font-tech text-white border-b-[1px]" htmlFor="">
        Links
      </label>
      {item.demo && (
        <div className="boxButtonLg">
          <Button to={item.demo ?? ""}>
            {" "}
            <Translator path="projects.demoButton" />{" "}
          </Button>
        </div>
      )}
      {item.repo && (
        <div className="boxButtonLg">
          <Button to={item.repo ?? ""}>
            {" "}
            <Translator path="projects.repositoryButton" />{" "}
          </Button>
        </div>
      )}
    </div>
  );
};
