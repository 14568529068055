import Slide from "react-reveal";

interface SkillProgressBarProps {
  progress: number;
}

export const SkillProgressBar = ({ progress }: SkillProgressBarProps) => {
  const progresArary = Array.from({ length: progress / 14 });
  return (
    <div className="p-1 w-full border-[1px] border-blue-200 rounded">
      <Slide left cascade delay={1500}>
        <div className={`flex gap-1 w-20 justify-start items-center h-[10px]`}>
          {progresArary.map((_, index) => (
            <div key={index} className="h-full min-w-2 max-w-2 bg-blue-200" />
          ))}
        </div>
      </Slide>
    </div>
  );
};
