import { TabContainer } from "../TabContainer/TabContainer";
import { Translator } from "../i18n/Translator";

export const AboutTab = () => {
  return (
    <TabContainer>
      <div className="flex flex-col p-2 lg:p-5 max-h-[65vh] lg:max-h-full w-full overflow-y-scroll">
        <h1 className="font-tech text-blue-200 text-4xl w-full hidden lg:block mb-3">
          <Translator path="about.title" />
        </h1>
        <p className="font-tech text-base text-white">
          <Translator path="about.description_p1" />
          <br />
          <br />
          <Translator path="about.description_p2" />
        </p>
      </div>
    </TabContainer>
  );
};
